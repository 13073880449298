import {HttpErrorResponse} from "@angular/common/http";


export class AresonError extends Error {

  isUnauthorized: boolean = false;

  constructor(httpError: HttpErrorResponse);
  // Second constructor: Takes a single string
  constructor(message: string);
  // Implementation of the constructors
  constructor(param: HttpErrorResponse | string) {
    console.error("HTTP Error:", param);
    // Determine which constructor was called based on the parameter type
    if (param instanceof HttpErrorResponse) {
      super(param?.error?.error || "Errore generico");
    } else {
      super(param);
    }
  }


}
