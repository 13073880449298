// src/environments/environment-class.ts

interface Endpoint {
  path: string;
  method: string;
}

export class Environment {
  production: boolean;
  urls: {
    userLogin: string;
    userHome: string;
    userProfile: string;
    tenantHome: string;
    roles: string;
  };
  apiUrl: string;
  endpoints: {
    login: Endpoint,
    isLogged: Endpoint,
    refreshToken: Endpoint,
    generateTenantToken: Endpoint,
    personalInfo: Endpoint,
    getUsers: Endpoint,
    createUser: Endpoint,
    updateUser: Endpoint,
    deleteUser: Endpoint,
    getRoles: Endpoint,
    createRole: Endpoint,
    updateRole: Endpoint,
    deleteRole: Endpoint,
    getPermissions: Endpoint,
  };

  constructor(apiUrl: string, production: boolean) {
    this.production = production;
    this.apiUrl = apiUrl;
    this.urls = {
      userLogin: "/user/login",
      userHome: "/user/home",
      userProfile: "/user/profile",
      tenantHome: "/tenant/home",
      roles: "/tenant/roles/"
    };
    this.endpoints = {
      login: {path: "/um/user/login", method: "post"},
      isLogged: {path: "/um/user/logged", method: "head"},
      refreshToken: {path: "/um/user/refresh-token", method: "head"},
      generateTenantToken: {path: "/um/user/tenant-token", method: "head"},
      personalInfo: {path: "/read/personal/info", method: "get"},
      getUsers: {path: "/read/tenant/users", method: "get"},
      createUser: {path: "/um/user", method: "post"},
      updateUser: {path: "/um/user", method: "put"},
      deleteUser: {path: "/um/user", method: "delete"},
      getRoles: {path: "/read/tenant/roles", method: "get"},
      createRole: {path: "/um/role", method: "post"},
      updateRole: {path: "/um/role", method: "put"},
      deleteRole: {path: "/um/role", method: "delete"},
      getPermissions: {path: "/um/role/available-permissions", method: "get"},
    };
  }

}
