import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Environment} from "../../environments/environment-class";
import {environment} from "../../environments/environment";
import {AresonError} from "../data-classes/error-types";

@Injectable({
  providedIn: 'root'
})
export class RequestsMiddlewareService {


  constructor(private http: HttpClient) {
  }

  //TODO chiamate concorrenti fanno andare in errore la rotta
  refreshToken(): Observable<void> {
    let endpoint = environment.endpoints["refreshToken"];
    let fullUrl: string = environment.apiUrl + endpoint.path;
    return this.http.head<void>(fullUrl, {withCredentials: true});
  }

  createRequest<T>(
    endpointName: keyof Environment['endpoints'],
    body: any,
    queryParams: any,
    responseType: String = 'json'
  ): Observable<T> {
    let endpoint = environment.endpoints[endpointName];
    let fullUrl: string = environment.apiUrl + endpoint.path;
    let makeRequest: () => Observable<T>;


    switch (endpoint.method.toLowerCase()) {
      case "get":
        makeRequest = () =>
          this.http.get<T>(fullUrl, {
            responseType: responseType as 'json', //TODO perchè as json
            withCredentials: true,
            params: queryParams,
          });
        break;
      case "post":
        makeRequest = () =>
          this.http.post<T>(fullUrl,
            body,
            {
              responseType: responseType as 'json', //TODO perchè as json
              withCredentials: true,
              params: queryParams
            });
        break;
      case "head":
        makeRequest = () =>
          this.http.head<T>(fullUrl,
            {
              responseType: responseType as 'json', //TODO perchè as json
              withCredentials: true,
              params: queryParams
            });
        break;
      case "delete":
        makeRequest = () =>
          this.http.delete<T>(fullUrl,
            {
              responseType: responseType as 'json', //TODO perchè as json
              withCredentials: true,
              params: queryParams
            });
        break;
      case "put":
        makeRequest = () =>
          this.http.put<T>(fullUrl,
            body,
            {
              responseType: responseType as 'json', //TODO perchè as json
              withCredentials: true,
              params: queryParams
            });
        break;
      default:
        return throwError(() => new AresonError("Undefined HTTP method"))
    }

    return makeRequest().pipe(
      catchError((error) => {
        return throwError(() => new AresonError(error));
      })
    );
  }
}
